.modes {
  margin-bottom: 1rem;
}

.onlinetest-container {
  padding: 70px 20px 0px 20px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.querybox-container {
  padding: 90px 20px 0px 20px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.page-container {
  padding: 60px 20px 0px 20px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.signin-container {
  width: 100%;
  min-height: 100vh;
}

.pdf-container {
  padding: 60px 20px 0px 20px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex
}

.video-container {
  padding: 10px 0px 0px 0px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 100vh; */
  display: flex;
  margin: auto
}

.video-container-landscape {
  /* padding: 60px 0px 0px 0px !important; */
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  margin: auto;
  margin-top: -60px;
}

.html-container {
  padding: 60px 20px 0px 20px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.page-container-5015 {
  padding: 50px 0px 0px 0px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
}

.page-container-5030 {
  padding: 50px 0px 0px 0px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
}

.page-container-5015_Activity {
  padding: 0px 0px 0px 0px !important;
  /* margin-bottom: 0.5rem;
      margin-left: auto;
      margin-right: auto; */
  width: 100%;
}

.page-container-0 {
  width: 100%;
  min-height: 100vh;
}

.ActivityViewerHeader {
  background-color: transparent !important;
  box-shadow: none !important;
}

.assm-instruction-container {
  padding: 50px 0px 0px 0px !important;
  /* margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto; */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.center-container {
  margin: auto;
  /* width: 65%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-signin {
  /* background-color: #341649; */
  background-color: #ffffff;
  background-image: url('../../img/login-min.png'), url(../../img/Other/web-quest_01.png), url(../../img/Other/web-quest_02_1.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom left, left top, top right;
  background-size: 680px 518px, 100px 100px, 288px 260px;
}

.background-home {
  background-color: #00b8ac;
  /* background-color: #341649; */
  background-image: url('../../img/dashboard-kids-min.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom left;
  background-size: 580px 418px;
}

.background-page-container-0 {
  background-color: #00b8ac;
}


/* usded in task */
.operation-container {
  min-height: 100vh;
  padding: 50px 32px 0px 0px !important;
}

.topics-container {
  padding: 60px 32px 0px 0px !important;
  background-color: #ffffff;
}


.background-video-player {
  background-color: #2C2C2C;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .operation-container {
    width: 100%;
    padding: 50px 0 0px !important;
  }

  .page-container {
    padding: 60px 0px 60px 0px !important;
  }

  .background-home {
    background-size: 480px 318px;
  }

  .background-signin {
    background-image: url('../../img/login-mobile-min.png'), url(../../img/Other/web-quest_01.png), url(../../img/Other/web-quest_02_1.png);
    background-size: 480px 318px, 100px 100px, 150px 100px;
  }

  .page-container-5030 {
    padding: 45px 0px 0px 0px !important;
  }

  .video-container {
    padding: 0px 0px 0px 0px !important;
  }

  .assm-instruction-container {
    padding: 45px 0px 0px 0px !important;
  }

  .html-container {
    padding: 45px 20px 0px 20px !important;
  }

  .pdf-container {
    padding: 45px 20px 0px 20px !important;
  }

  .querybox-container {
    padding: 50px 0px 60px 0px !important;
  }

  .onlinetest-container {
    padding: 45px 0px 0px 0px !important;
  }
}

/* Page Moveing animation */
.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}